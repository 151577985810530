import axios, { AxiosError, AxiosRequestConfig } from "axios";

export const callCoreApi = async (
  config: AxiosRequestConfig
): Promise<{
  responseData: any | null;
  error: { message: string } | null;
  status: number | undefined;
  errorData: any | null;
  
}> => {
  try {
    const response = await axios(config);
    const { data } = response;
    return { responseData: data, error: null, status: response.status, errorData: null };
  } catch (error) {
    const axiosError = error as AxiosError;
      return {
        responseData: null,
        error: { message: axiosError.message },
        status: axiosError.response?.status,
        errorData: axiosError.response?.data,
      };
  }
};
