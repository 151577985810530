import React, { ReactNode } from "react";

type AlertProps = {
  message: ReactNode;
  status: "error" | "success";
};

export function Alert(props: AlertProps) {
  return (
    <>
      {props.status === "error" && (
        <div
          className={`alert alert-error shadow-lg max-w-5xl w-11/12 fixed top-10 left-2/4 -translate-x-2/4 z-[1000]`}>
          <div>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              className='stroke-current flex-shrink-0 h-6 w-6'
              fill='none'
              viewBox='0 0 24 24'>
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='2'
                d='M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z'
              />
            </svg>
            <span>{props.message}</span>
          </div>
        </div>
      )}
      {props.status === "success" && (
        <div
          className={`alert alert-success shadow-lg max-w-5xl w-11/12 fixed top-10 left-2/4 -translate-x-2/4 z-50`}>
          <div>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              className='stroke-current flex-shrink-0 h-6 w-6'
              fill='none'
              viewBox='0 0 24 24'>
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='2'
                d='M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z'
              />
            </svg>
            <span>{props.message}</span>
          </div>
        </div>
      )}
    </>
  );
}
