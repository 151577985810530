import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

const SocialLogoutButton = () => {
  const { logout } = useAuth0();

  return (
    <button
      onClick={() =>
        logout({
          logoutParams: {
            returnTo: `${window.location.origin}/login`,
          },
        })
      }
      className="btn btn-primary text-white w-[200px] block sm:mx-auto mx-0"
    >
      ログアウト
    </button>
  );
};

export default SocialLogoutButton;
