import React from "react";
import { useNavigate } from "react-router-dom";

function NoMatch() {
  const navigate = useNavigate();
  return (
    <div className='modal modal-open'>
      <div className='modal-box py-14 sm:px-4 flex flex-col gap-10 max-w-[720px] px-[140px]'>
        <p className=' text-2xl text-center font-bold'>404</p>
        <p>
          <span className='font-bold'>お探しのページが見つかりません。</span>
          <br />
          ページが削除されたか、URLの入力間違いの可能性があります。
          正しいURLをご入力いただくか、下記のボタンからホームにお戻りください。
        </p>
        <div className='flex sm:flex-col flex-row justify-center items-center gap-10'>
          <button
            className='btn btn-primary text-white w-[200px] block sm:mx-auto mx-0'
            onClick={() => navigate("/")}>
            ホームヘ戻る
          </button>
        </div>
      </div>
    </div>
  );
}

export default NoMatch;
