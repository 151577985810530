import React, {useEffect, useContext} from 'react';
import PageTitle from 'components/PageTitle';
import Button from 'components/Button';
import {useOutletContext} from 'react-router-dom';
import {AuthUserContext, AuthUserContextType} from 'providers/AuthUserProvider';

function Career() {
	const {cookies, setIsCareerSupportTutorialModalOpen} = useOutletContext() as {
		cookies: {isCareerSupportTutorial: boolean};
		setIsCareerSupportTutorialModalOpen: (isOpen: boolean) => void;
	};
	const authUserContext = useContext<AuthUserContextType>(AuthUserContext);
	const zeroplusId = authUserContext.user?.zeroplus_id;
	const sanitaizeZeroplusId = zeroplusId?.replace(/-/g, '');
	useEffect(() => {
		if (cookies.isCareerSupportTutorial === undefined) {
			setIsCareerSupportTutorialModalOpen(true);
		}
	}, []);

	const careerSupportMovieCardData = [
		{
			title: '案件獲得講義-副業/フリーランス向け',
			videoSrc:
				'https://storage.googleapis.com/zeroplus-portal/%E5%89%AF%E6%A5%AD%E3%83%95%E3%83%AA%E3%83%BC%E3%83%A9%E3%83%B3%E3%82%B9%EF%BC%88%E6%A1%88%E4%BB%B6%E7%8D%B2%E5%BE%97%EF%BC%89.mp4',
			description:
				'副業やフリーランスを目指す方必見！案件獲得までの全体の流れや、営業に関するノウハウが詰まった講座となっています。'
		},
		{
			title: '自己分析',
			videoSrc:
				'https://storage.googleapis.com/zeroplus-portal/%E8%87%AA%E5%B7%B1%E5%88%86%E6%9E%90%E6%96%B9%E6%B3%95.mp4',
			description:
				'就職や転職を考えている方向けの講座です。自己分析を通して、自分の目指すべき方向性などを明確にしていくことができます。'
		},
		{
			title: '転職講座',
			videoSrc:
				'https://storage.googleapis.com/zeroplus-portal/%E8%BB%A2%E8%81%B7%E7%89%B9%E5%88%A5%E5%8B%95%E7%94%BB.mp4',
			description: '転職を考えている方必見！転職活動をうまく運ぶ上で大切な企業選びなどのポイントがご覧いただけます。'
		},
		{
			title: '就職講座',
			videoSrc:
				'https://storage.googleapis.com/zeroplus-portal/%E5%B0%B1%E6%B4%BB%E7%89%B9%E5%88%A5%E8%AC%9B%E7%BE%A9.mp4',
			description:
				'就職を考えている方必見！就職活動において重要な、就活の流れや、自己分析、企業選び等がご覧いただけます。'
		},
		{
			title: 'タイムマネジメント',
			videoSrc:
				'https://storage.googleapis.com/zeroplus-portal/%E3%82%BF%E3%82%A4%E3%83%A0%E3%83%9E%E3%83%8D%E3%82%B7%E3%82%99%E3%83%A1%E3%83%B3%E3%83%88.mp4',
			description:
				'学習をしていく上で大切な「時間管理」についての講座です。もっと学習効率を上げて、レベルアップしたい方は必見です！'
		}
	];
	return (
		<div className='mx-4'>
			{/* TODO: props.overviewをどのようにして改行できるようにするか考えて、修正する */}
			<PageTitle
				title='キャリア相談'
				overview='キャリア相談は、まず動画を視聴し、解決しない場合にGoogle Formから面談をお申し込みいただけます。
        フォームの送信後、SlackのDMにて担当者からご連絡差し上げます。'
			/>

			<div className=' grid grid-cols-2 sm:grid-cols-1 place-items-center gap-10'>
				{careerSupportMovieCardData.map((data, index) => (
					<CareerSupportMovieCard
						key={index}
						title={data.title}
						videoSrc={data.videoSrc}
						description={data.description}
					/>
				))}
			</div>

			<div className='mt-20 px-8 py-10 bg-white rounded-lg text-center'>
				<h3 className='text-2xl font-bold mb-8'>ご利用に関して</h3>
				<div className='flex sm:flex-col justify-center gap-10'>
					<div className='flex justify-center font-bold'>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							fill='none'
							viewBox='0 0 24 24'
							strokeWidth={1.5}
							stroke='currentColor'
							className='w-6 h-6 mr-2'
						>
							<path strokeLinecap='round' strokeLinejoin='round' d='M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z' />
						</svg>
						<span>相談可能時間</span>
					</div>
					<span>ご相談は10:00-18:00の時間帯で受け付けております。</span>
				</div>
			</div>

			<div className='px-8 py-10 mb-40 bg-white rounded-lg text-center mt-20'>
				<h3 className='text-2xl font-bold mb-8'>キャリア相談のお申し込み</h3>
				<p className='mb-10'>キャリア相談を希望する旨のメッセージと希望日程をお送りください。</p>
				{/* TODO: Google Formのリンクに変更する  */}
				<Button
					title='Google Formへ'
					to={`https://docs.google.com/forms/d/e/1FAIpQLSciEuQdglnHNaJFx8CqpuoruAAy8rEXYdLs8xbC5G_ew_LnVg/viewform?usp=pp_url&entry.529911003=${sanitaizeZeroplusId}`}
					isExternal
				/>
			</div>
		</div>
	);
}
type CareerSupportMovieCardProps = {
	title: string;
	videoSrc: string;
	description: string;
};

function CareerSupportMovieCard({title, videoSrc, description}: CareerSupportMovieCardProps) {
	return (
		<div className='card w-full max-w-[496px] min-h-[470px] bg-base-100 rounded-lg shadow-md'>
			<figure>
				<video width={496} height={279} src={videoSrc} controls controlsList='nodownload'></video>
			</figure>
			<div className='card-body'>
				<h2 className='card-title mb-6'>{title}</h2>
				<p className='mb-10'>{description}</p>
			</div>
		</div>
	);
}

export default Career;
