import React from "react";
import PortalSectionTitle from "components/PortalSectionTitle";
import folowwshipEventImg from "assets/images/fellowship_event_image.png";

function FellowshipEvent() {
  const fellowshipEventImg = {
    backgroundImage: `url(${folowwshipEventImg})`,
  };
  return (
    <div className='px-4 mt-[120px]'>
      <h2 className=' text-2xl font-bold text-center'>ZeroPlus交流会</h2>
      <p className='text-center mt-6'>
        オンライン交流会のお申し込みは以下のフォームから受け付けております。
        <br />
        皆様のご参加をお待ちしております。
      </p>
      <div className='mt-20 gap-20 w-full'>
        <div className='flex flex-col gap-10 max-w-3xl mx-auto'>
          <PortalSectionTitle title='交流会のお申し込み' />
          <div
            className='mt-10 w-full h-[284px] sm:aspect-[343/400] aspect-[696/360] bg-base-100 shadow-xl image-full bg-cover relative z-0 rounded-2xl before:block before:w-full before:h-full before:absolute before:bg-black before:-z-10 before:opacity-50 before:rounded-2xl'
            style={fellowshipEventImg}>
            <div className='w-hull h-full rounded-lg flex flex-col gap-4 justify-center items-center text-white'>
              <p className='font-bold'>開催：毎月第二週の土曜日 19:00-21:00</p>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                strokeWidth={1.5}
                stroke='currentColor'
                className='w-6 h-6'>
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  d='M12 8.25v-1.5m0 1.5c-1.355 0-2.697.056-4.024.166C6.845 8.51 6 9.473 6 10.608v2.513m6-4.87c1.355 0 2.697.055 4.024.165C17.155 8.51 18 9.473 18 10.608v2.513m-3-4.87v-1.5m-6 1.5v-1.5m12 9.75l-1.5.75a3.354 3.354 0 01-3 0 3.354 3.354 0 00-3 0 3.354 3.354 0 01-3 0 3.354 3.354 0 00-3 0 3.354 3.354 0 01-3 0L3 16.5m15-3.38a48.474 48.474 0 00-6-.37c-2.032 0-4.034.125-6 .37m12 0c.39.049.777.102 1.163.16 1.07.16 1.837 1.094 1.837 2.175v5.17c0 .62-.504 1.124-1.125 1.124H4.125A1.125 1.125 0 013 20.625v-5.17c0-1.08.768-2.014 1.837-2.174A47.78 47.78 0 016 13.12M12.265 3.11a.375.375 0 11-.53 0L12 2.845l.265.265zm-3 0a.375.375 0 11-.53 0L9 2.845l.265.265zm6 0a.375.375 0 11-.53 0L15 2.845l.265.265z'
                />
              </svg>

              <p className='text-center font-bold'>
                事前お申し込み受付中！
                <br />
                お申し込みはお早めに！
              </p>
            </div>
          </div>
          <button
            className='btn btn-primary block mx-auto text-white w-[200px] h-16 normal-case'
            onClick={() => window.open("https://line.me/ti/p/%40078mshyn")}>
            参加フォームへ
          </button>
        </div>
      </div>
    </div>
  );
}

export default FellowshipEvent;
