import React, {useEffect, useContext} from 'react';
import Thumb1 from 'assets/images/thumb_nomal-class.png';
import Thumb2 from 'assets/images/thumb_homework.png';
import Thumb3 from 'assets/images/thumb_study-mtg.png';
import Thumb4 from 'assets/images/thumb_community.png';
import Thumb5 from 'assets/images/thumb_entry-exam_download.png';
import {useOutletContext} from 'react-router-dom';
import Button from 'components/Button';
import {AuthUserContext, AuthUserContextType} from 'providers/AuthUserProvider';

function Top() {
	const authUserContext = useContext<AuthUserContextType>(AuthUserContext);
	const currentStatus = authUserContext.contractStudetData?.current_status;
	const zeroplusId = authUserContext.user?.zeroplus_id;
	const sanitaizeZeroplusId = zeroplusId?.replace(/-/g, '');

	const webDesignStudyMtgUrl = 'https://us02web.zoom.us/j/86061026190';
	const webAppStudyMtgUrl = 'https://discord.gg/CEtEGQABDa';
	const communityUrl = 'https://forms.gle/4GX1pjkef1hWptke7';
	const postHomeWorkUrl = `https://docs.google.com/forms/d/e/1FAIpQLScjiCv93dhQqFwIN7F76eJHODIXFhhFPL57ofUl8YZQ-dlIIA/viewform?usp=pp_url&entry.1700501470=${sanitaizeZeroplusId}`;
	const wepAppHomeWorkUrl = `https://docs.google.com/forms/d/e/1FAIpQLSfnVFD24JZmx5sFNpJxtbQnWpy7uYk8rgVzk6lrORgn21KB8g/viewform?usp=pp_url&entry.1072678832=${sanitaizeZeroplusId}`;
	const {cookies, setIsFirstTutorialModalOpen} = useOutletContext() as {
		cookies: {isTopTutorial: boolean};
		setIsFirstTutorialModalOpen: (isOpen: boolean) => void;
	};
	const cartdTitleGradationBorderStyle = {
		borderLeft: '8px solid',
		borderImage: 'linear-gradient(90deg, #FFB723 0%, #FF9552 100%) 1'
	};

	const pageTitleGradationBorderStyle = {
		border: '8px solid',
		borderImage: 'linear-gradient(90deg, #FFB723 0%, #FF9552 100%) 1'
	};
	const contractCourseName = authUserContext.userContractCourseName;

	useEffect(() => {
		if (cookies.isTopTutorial === undefined) {
			setIsFirstTutorialModalOpen(true);
		}
	}, []);
	return (
		<div className='mx-4 mb-10'>
			<div style={pageTitleGradationBorderStyle} className='bg-white w-full mb-20'>
				<div className='py-10 text-center'>
					<p className='font-bold text-lg'>ZeroPlus ポータルサイト</p>
					<p className='mt-6'>
						ZeroPlusポータルサイトは、ZeroPlusに関する情報が掲載されているサイトです。
						<br />
						当ページの下側や、左側のメニューから各サービスの情報をご覧いただけます。
					</p>
				</div>
			</div>
			<div className='grid grid-cols-2 sm:grid-cols-1 gap-10 mx-auto place-items-center'>
				{currentStatus === 0 ? (
					<>
						<CustomCard
							thumbnail={Thumb5}
							title='入学テストの実施'
							description={
								contractCourseName === 'Webデザイン'
									? '入学テストを実施する場合は、こちらの資料から。合格することで、晴れて授業を受けることができるようになります！頑張りましょう！'
									: contractCourseName === 'Webアプリケーション'
									? '入学テストを実施する場合は、こちらのNotionから。合格することで、晴れて授業を受けることができるようになります！頑張りましょう！'
									: ''
							}
							buttonTitle={
								contractCourseName === 'Webデザイン'
									? '入学テストの資料はこちら'
									: contractCourseName === 'Webアプリケーション'
									? 'Notionはこちら'
									: ''
							}
							buttonLink={
								contractCourseName === 'Webデザイン'
									? 'https://zeroplus.notion.site/ZeroPlus-c9b5a8cd509d4ad98d35e3c3b92eecda'
									: contractCourseName === 'Webアプリケーション'
									? 'https://zeroplus.notion.site/Web-0b7da80a94454f26a619f842843459f0'
									: ''
							}
							isExternal={true}
						/>
					</>
				) : (
					<>
						<CustomCard
							thumbnail={Thumb1}
							title='通常講義'
							description='通常講義に関しての、参加URL(GoogleMeet)、授業スケジュール、授業教材などはこちらのページをご覧ください。'
							buttonTitle='詳細はこちら'
							buttonLink='/lesson'
						/>

						<CustomCard
							thumbnail={Thumb2}
							title='課題提出'
							description='課題お疲れ様でした！提出はこちらのGoogle Formから。通常講義の課題提出も、追加課題の提出も。'
							buttonTitle='課題を提出する'
							buttonLink={contractCourseName === 'Webデザイン' ? postHomeWorkUrl : wepAppHomeWorkUrl}
							isExternal={true}
						/>
					</>
				)}
				<CustomCard
					thumbnail={Thumb3}
					title='勉強会'
					description={`勉強会の参加URL(${
						contractCourseName === 'Webデザイン' ? 'Zoom' : 'Discord'
					})はこちら。講師に直接プログラミングの質問ができる！たまにはみんなでワイワイ楽しく勉強しませんか?`}
					buttonTitle='勉強会に参加する'
					buttonLink={contractCourseName === 'Webデザイン' ? webDesignStudyMtgUrl : webAppStudyMtgUrl}
					isExternal={true}
				/>
				<CustomCard
					thumbnail={Thumb4}
					title='交流会の申し込み'
					description={`次回交流会の申し込みはこちらのGoogle Formから。クラスの垣根を超えた仲間との「出会い」や、現役エンジニアとの「頼れる人脈」ができるかも!?`}
					buttonTitle='交流会に申し込む'
					buttonLink={communityUrl}
					isExternal={true}
				/>
			</div>
		</div>
	);
}

type CustomCardProps = {
	thumbnail: string;
	title: string;
	description: string;
	buttonTitle: string;
	buttonLink: string;
	isExternal?: boolean;
};

const CustomCard = ({thumbnail, title, description, buttonTitle, buttonLink, isExternal}: CustomCardProps) => {
	const cartdTitleGradationBorderStyle = {
		borderLeft: '8px solid',
		borderImage: 'linear-gradient(90deg, #FFB723 0%, #FF9552 100%) 1'
	};

	return (
		<div className='card w-full max-w-[496px] bg-base-100 shadow-md rounded-lg'>
			<figure>
				<img src={thumbnail} alt='img' />
			</figure>
			<div className='card-body'>
				<h2 style={cartdTitleGradationBorderStyle} className='card-title pl-4 mb-6'>
					{title}
				</h2>
				<p className='mb-10'>{description}</p>
				<div className='flex justify-center'>
					<Button title={buttonTitle} to={buttonLink} isExternal={isExternal} />
				</div>
			</div>
		</div>
	);
};

export default Top;
