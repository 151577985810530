import React from "react";
import PageTitle from "components/PageTitle";

function PrivacyPolicy() {
  return (
    <div className="mx-4">
      <PageTitle
        title="プライバシーポリシー"
        overview="株式会社TOMAP（以下「当社」といいます）は、以下のとおり個人情報保護方針を定め、個人情報保護の仕組みを構築し、全従業員に個人情報保護の重要性の認識と取組みを徹底させることにより、個人情報の保護を推進致します。"
      />
      <div className="px-44 py-10 sm:px-8 min-h-[54px] w-full bg-white rounded-lg">
        <h3 className="text-lg font-bold mb-6">個人情報の管理</h3>
        <p className="mb-10 leading-8">
          当社は、お客さまの個人情報を正確かつ最新の状態に保ち、個人情報への不正アクセス・紛失・破損・改ざん・漏洩などを防止するため、セキュリティシステムの維持・管理体制の整備・社員教育の徹底等の必要な措置を講じ、安全対策を実施し個人情報の厳重な管理を行ないます。
        </p>
        <h3 className="text-lg font-bold mb-6">個人情報の利用目的</h3>
        <p className="mb-10 leading-8">
          お客さまからお預かりした個人情報は、当社からのご連絡や業務のご案内やご質問に対する回答として、電子メールや資料のご送付に利用いたします。個人情報の第三者への開示・提供の禁止当社は、お客さまよりお預かりした個人情報を適切に管理し、次のいずれかに該当する場合を除き、個人情報を第三者に開示いたしません。
        </p>
        <p className="mb-10 leading-8">
          (1) 本人確認のため
          <br />
          (2) 講座等の申込、問い合わせ内容の確認及び本契約の遂行のため
          <br />
          (3)
          申込者本人からの質問、講座、説明会、各種イベント及び関連事業等に関する案内等各種情報の提供、送付のため
          <br />
          (4) 本契約に関する案内、問い合わせ、アンケート等の依頼、送付のため
          <br />
          (5) 本契約及び業務に関連付随する市場調査、データ収集、分析のため
          <br />
          (6)
          お客さまの同意がある場合お客さまが希望されるサービスを行なうために当社が業務を委託する業者に対して開示する場合
          法令に基づき開示することが必要である場合
          <br />
          (7) その他前各号に関連付随する業務遂行のため
        </p>
        <h3 className="text-lg font-bold mb-6">個人情報の安全対策</h3>
        <p className="mb-10 leading-8">
          当社は、個人情報の正確性及び安全性確保のために、セキュリティに万全の対策を講じています。
        </p>
        <h3 className="text-lg font-bold mb-6">ご本人の照会</h3>
        <p className="mb-10 leading-8">
          お客さまがご本人の個人情報の照会・修正・削除などをご希望される場合には、ご本人であることを確認の上、対応させていただきます。
        </p>
        <h3 className="text-lg font-bold mb-6">法令、規範の遵守と見直し</h3>
        <p className="mb-10 leading-8">
          当社は、保有する個人情報に関して適用される日本の法令、その他規範を遵守するとともに、本ポリシーの内容を適宜見直し、その改善に努めます。
        </p>
        <h3 className="text-lg font-bold mb-6">お問い合わせ</h3>
        <p className="mb-10 leading-8">
          当社の個人情報の取扱に関するお問い合せは下記までご連絡ください。
        </p>
        <p className="leading-8">
          株式会社 TOMAP
          <br />
          〒150-0001 東京都渋谷区神宮前3丁目1-2 神宮前Ikビル3F
          <br />
          TEL : 03-6912-5250(定休日：月曜)
          <br />
          Mail : info@tomap.co
          <br />
          対応可能時間：10:00~18:00
          <br />
        </p>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
