import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import LogoImg from "assets/images/zeroplus_logo.png";

function Login() {
  const { loginWithRedirect } = useAuth0();
  const handleSocialLogin = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: "/",
      },
      authorizationParams: {
        prompt: "login",
      },
    });
  };
  return (
    <>
      <div className="h-screen w-screen flex justify-center items-center">
        <div className="max-w-md mx-auto w-full p-4">
          <img
            className=" w-32 mx-auto"
            src={LogoImg}
            alt="ZeroPlus Core logo"
          />
          {/* Page Content */}
          <div className="flex justify-center mt-10">
            <button className="btn btn-primary" onClick={handleSocialLogin}>
              ログインはこちら
            </button>
          </div>
          {/* End Page Content */}
        </div>
      </div>
    </>
  );
}

export default Login;
