import React, {useEffect} from 'react';
import SocialLogoutButton from 'components/SocialLogoutButton';
import {useAuth0} from '@auth0/auth0-react';
import {useNavigate} from 'react-router-dom';

function NoCoursePage() {
	const {user} = useAuth0();
	const navigate = useNavigate();
	const mail_address = user?.email;
	useEffect(() => {
		if (user?.['https://zero-plus.io/user_metadata'].zeroplus_id === undefined) {
			navigate('/first-access');
		}
	}, [user]);
	return (
		<div className='modal modal-open'>
			<div className='modal-box flex flex-col gap-4 justify-center items-center'>
				<h3 className='font-bold text-lg'>受講中のコースがございません。</h3>
				<p className='py-4'>
					受講期間中もこの画面が直らない場合は、
					<a
						href={`${process.env.REACT_APP_CONTACT_FORM_URL}&entry.879531967=${mail_address}`}
						className=' text-[#5AC5ED] underline'
						target='_blank'
						rel='noopener noreferrer'
					>
						こちら
					</a>
					からお問い合わせください。
				</p>
				<SocialLogoutButton />
				<div className='modal-action'></div>
			</div>
		</div>
	);
}

export default NoCoursePage;
