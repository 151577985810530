import React, { useState } from "react";

function Survey() {
  const [lesson, setLesson] = useState(1);
  return (
    <>
      <div>
        <p>Lesson{lesson}回授業後アンケート</p>
      </div>
      <div className="alert alert-error shadow-lg">
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            className="stroke-info flex-shrink-0 w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            ></path>
          </svg>
          <span>回答期限が過ぎています</span>
        </div>
      </div>
    </>
  );
}
export default Survey;
