import React from 'react';
import {Link} from 'react-router-dom';

type ButtonProps = {
	title: string;
	to: string;
	isExternal?: boolean;
};

function Button(props: ButtonProps) {
	const gradientButtonStyle = {
		backgroundImage: 'linear-gradient(to right, #FFB723 50%, #FF9552 100%)',
		color: '#FFFFFF',
		border: 'none',
		padding: 0,
		textTransform: 'none' as const
	};

	const externalAttributes = props.isExternal
		? {
				target: '_blank',
				rel: 'noopener noreferrer'
		  }
		: {};

	return (
		<Link
			style={gradientButtonStyle}
			className='btn btn-lg w-full max-w-[320px] gap-2 tracking-wider relative'
			to={props.to}
			{...externalAttributes}
		>
			{props.title}
			<svg
				xmlns='http://www.w3.org/2000/svg'
				fill='none'
				viewBox='0 0 24 24'
				strokeWidth={3}
				stroke='currentColor'
				className='w-4 h-4 absolute right-4 top-1/2 -translate-y-1/2'
			>
				<path strokeLinecap='round' strokeLinejoin='round' d='M8.25 4.5l7.5 7.5-7.5 7.5' />
			</svg>
		</Link>
	);
}

export default Button;
