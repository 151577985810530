import React from "react";

type PageTitleProps = {
  title: string;
  overview?: string;
};

function PageTitle(props: PageTitleProps) {
  const gradientBorderStyle = {
    borderLeft: "8px solid",
    borderImageSlice: 1,
    borderImageSource: "linear-gradient(to right, #FFB723 20%, #FF9552 100%)",
  };

  return (
    <div className="px-8 py-10 sm:px-4 mb-20 min-h-[54px] w-full bg-white rounded-lg">
      <div style={gradientBorderStyle} className="pl-6">
        <h2 className="text-2xl font-bold mb-6">{props.title}</h2>
        {props.overview && (
          <p className="font-bold leading-8">{props.overview}</p>
        )}
      </div>
    </div>
  );
}

export default PageTitle;
