import React, {useEffect, useContext} from 'react';
import PageTitle from 'components/PageTitle';
import Button from 'components/Button';
import {useOutletContext} from 'react-router-dom';
import {AuthUserContext, AuthUserContextType} from 'providers/AuthUserProvider';

function QuestionSupport() {
	const {cookies, setIsQuestionSupportTutorialModalOpen} = useOutletContext() as {
		cookies: {isQuestionSupportTutorial: boolean};
		setIsQuestionSupportTutorialModalOpen: (isOpen: boolean) => void;
	};
	const authUserContext = useContext<AuthUserContextType>(AuthUserContext);
	const zeroplusId = authUserContext.user?.zeroplus_id;
	const sanitaizeZeroplusId = zeroplusId?.replace(/-/g, '');
	const contractCourseName = authUserContext.userContractCourseName;

	useEffect(() => {
		if (cookies.isQuestionSupportTutorial === undefined) {
			setIsQuestionSupportTutorialModalOpen(true);
		}
	}, []);

	return (
		<div className='mx-4 mb-10'>
			{/* TODO: props.overviewをどのようにして改行できるようにするか考えて、修正する */}
			<PageTitle
				title='質問サポート'
				overview='ご質問いただく内容によって以下の２つの方法から受け付けております。質問対応の混み合い状況によって回答までのお時間をいただく場合がございます。'
			/>

			<div className='grid grid-cols-2 sm:grid-cols-1 grid-rows-1 gap-8 mb-20'>
				<div className='px-8 py-10 bg-white rounded-lg'>
					<h3 className='flex items-center text-xl font-bold mb-10'>
						<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' className='w-6 h-6 mr-2'>
							<circle cx='12' cy='12' r='12' fill='url(#paint0_linear_1778_4054)' />
							<mask id='path-2-inside-1_1778_4054' fill='white'>
								<path d='M13.6606 4L18.8029 10.1284L10.3765 17.199L5.23416 11.0707L13.6606 4Z' />
							</mask>
							<path
								d='M10.3765 17.199L8.84437 18.4846L10.1299 20.0167L11.662 18.7311L10.3765 17.199ZM17.5174 8.59627L9.09088 15.6669L11.662 18.7311L20.0885 11.6604L17.5174 8.59627ZM11.9085 15.9134L6.76624 9.78509L3.70207 12.3562L8.84437 18.4846L11.9085 15.9134Z'
								fill='white'
								mask='url(#path-2-inside-1_1778_4054)'
							/>
							<defs>
								<linearGradient
									id='paint0_linear_1778_4054'
									x1='0'
									y1='12'
									x2='24'
									y2='12'
									gradientUnits='userSpaceOnUse'
								>
									<stop stopColor='#FFB723' />
									<stop offset='1' stopColor='#FF9552' />
								</linearGradient>
							</defs>
						</svg>
						<span>プログラミングに関するご質問</span>
					</h3>
					<p className='leading-8 mb-10'>予習復習や授業でわからなかった箇所など、こちらのGoogle Formから。</p>
					<div className='text-center'>
						{/* TODO: リンクを修正する */}
						<Button
							title='Google Formへ'
							to={
								contractCourseName === 'Webデザイン'
									? `https://docs.google.com/forms/d/e/1FAIpQLSffJAMtyesbUYx1tSQlKpwNEDdgZ6JsNWjakU4zLJzPdgelzg/viewform?usp=pp_url&entry.1700501470=${sanitaizeZeroplusId}`
									: contractCourseName === 'Webアプリケーション'
									? `https://docs.google.com/forms/d/e/1FAIpQLSfTv27vaoGDlhdX-typpm0cDt0KjcRVzByBpygTbwsBK7Epmg/viewform?usp=pp_url&entry.213251187=${sanitaizeZeroplusId}`
									: ''
							}
							isExternal
						/>
					</div>
				</div>

				<div className='px-8 py-10 bg-white rounded-lg'>
					<h3 className='flex items-center text-xl font-bold mb-10'>
						<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' className='w-6 h-6 mr-2'>
							<circle cx='12' cy='12' r='12' fill='url(#paint0_linear_1778_4054)' />
							<mask id='path-2-inside-1_1778_4054' fill='white'>
								<path d='M13.6606 4L18.8029 10.1284L10.3765 17.199L5.23416 11.0707L13.6606 4Z' />
							</mask>
							<path
								d='M10.3765 17.199L8.84437 18.4846L10.1299 20.0167L11.662 18.7311L10.3765 17.199ZM17.5174 8.59627L9.09088 15.6669L11.662 18.7311L20.0885 11.6604L17.5174 8.59627ZM11.9085 15.9134L6.76624 9.78509L3.70207 12.3562L8.84437 18.4846L11.9085 15.9134Z'
								fill='white'
								mask='url(#path-2-inside-1_1778_4054)'
							/>
							<defs>
								<linearGradient
									id='paint0_linear_1778_4054'
									x1='0'
									y1='12'
									x2='24'
									y2='12'
									gradientUnits='userSpaceOnUse'
								>
									<stop stopColor='#FFB723' />
									<stop offset='1' stopColor='#FF9552' />
								</linearGradient>
							</defs>
						</svg>
						<span>サービス利用に関するご質問</span>
					</h3>
					<p className='leading-8 mb-10'>ZeroPlusのご利用に関する質問は、こちらのGoogle Formから。</p>
					<div className='text-center'>
						{/* TODO: リンクを修正する */}
						<Button
							title='Google Formへ'
							to={
								contractCourseName === 'Webデザイン'
									? `https://docs.google.com/forms/d/e/1FAIpQLSffJAMtyesbUYx1tSQlKpwNEDdgZ6JsNWjakU4zLJzPdgelzg/viewform?usp=pp_url&entry.1700501470=${sanitaizeZeroplusId} `
									: contractCourseName === 'Webアプリケーション'
									? `https://docs.google.com/forms/d/e/1FAIpQLSfTv27vaoGDlhdX-typpm0cDt0KjcRVzByBpygTbwsBK7Epmg/viewform?usp=pp_url&entry.213251187=${sanitaizeZeroplusId} `
									: ''
							}
							isExternal
						/>
					</div>
				</div>
			</div>

			<div className='py-10 px-8 bg-white rounded-lg'>
				<div className='max-w-[672px] m-auto'>
					<h3 className='text-2xl font-bold mb-8 text-center'>ご利用に関して</h3>
					<dl>
						<div className='flex lg:flex-col lg:gap-4 items-center mb-6'>
							<dt className='flex shrink-0 font-bold mr-14'>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									fill='none'
									viewBox='0 0 24 24'
									strokeWidth={1.5}
									stroke='currentColor'
									className='w-6 h-6 mr-2'
								>
									<path
										strokeLinecap='round'
										strokeLinejoin='round'
										d='M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z'
									/>
								</svg>
								<span>質問可能時間</span>
							</dt>
							<dd>
								24時間365日ご質問していただくことが可能です。
								<br />
								疑問が出たら積極的に聞きましょう！
							</dd>
						</div>
						<div className='flex lg:flex-col lg:gap-4 items-center'>
							<dt className='flex shrink-0 font-bold mr-14'>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									fill='none'
									viewBox='0 0 24 24'
									strokeWidth={1.5}
									stroke='currentColor'
									className='w-6 h-6 mr-2'
								>
									<path
										strokeLinecap='round'
										strokeLinejoin='round'
										d='M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z'
									/>
								</svg>
								<span>回答返却時間</span>
							</dt>
							<dd>
								質問への回答は「<b>10:00〜22:00</b>」に順次返却いたします。
								<br />
								18:30以降のご質問は翌日に回答されます。
							</dd>
						</div>
					</dl>
				</div>
			</div>
		</div>
	);
}

export default QuestionSupport;
