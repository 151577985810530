import React from "react";

function PortalSectionTitle(props: any) {
  return (
    <div className="bg-white min-h-[54px] w-full rounded-lg flex items-center">
      <h2 className="text-xl ml-5 font-bold">{props.title}</h2>
    </div>
  );
}

export default PortalSectionTitle;
