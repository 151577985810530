import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Auth0ProviderWithNavigate } from "auth0ProviderWithNavigate";
import { AuthUserProvider } from "providers/AuthUserProvider";
import { CookiesProvider } from "react-cookie";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
// const { worker } = require("./mocks/browser");
// worker.start();
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Auth0ProviderWithNavigate>
        <AuthUserProvider>
          <CookiesProvider>
            <App />
          </CookiesProvider>
        </AuthUserProvider>
      </Auth0ProviderWithNavigate>
    </BrowserRouter>
  </React.StrictMode>
);

console.log(`
 ________                             ____     ___                       
/\\_____  \\                           /\\  _\`\\  /\\_ \\                      
\\/____//'/'      __    _ __    ___   \\ \\ \\L\\ \\\\//\\ \\     __  __    ____  
     //'/'     /'__\`\\ /\\ \`'_\\ / __\`\\  \\ \\ ,__/  \\ \\ \\   /\\ \\/\\ \\  /',__\\ 
    //'/'___  /\\  __/ \\ \\ \\/ /\\ \\L\\ \\  \\ \\ \\/    \\_\\ \\_ \\ \\ \\_\\ \\/\\__, \`\\
    /\\_______\\\\ \\____\\ \\ \\_\\ \\ \\____/   \\ \\_\\    /\\____\\ \\ \\____/\\/\\____/
    \\/_______/ \\/____/  \\/_/  \\/___/     \\/_/    \\/____/  \\/___/  \\/___/ 
`);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(null);
