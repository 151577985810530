import React, { useState, createContext } from "react";
import { UserType, ContractStudentType } from "../types";

interface AuthUserContextType {
  user: UserType | null;
  userContractCourseName?: "Webデザイン" | "Webアプリケーション";
  setUserContractCourseName: (
    courseName: "Webデザイン" | "Webアプリケーション"
  ) => void;
  isUserRegistered: number;
  signin: (signinUser: UserType, callback?: () => void) => void;
  signout: (callback: () => void) => void;
  contractStudetData?: ContractStudentType;
  setContractStudentData: (contractStudentData: ContractStudentType) => void;
  studentClassroomData?: any;
  setStudentClassroomData: (studentClassroomData: any) => void;
}

interface Props {
  children: React.ReactNode;
}

const AuthUserContext = createContext<AuthUserContextType>(
  {} as AuthUserContextType
);

const AuthUserProvider = (props: Props) => {
  const [user, setUser] = useState<UserType | null>(null);
  const [isUserRegistered, setIsUserRegistered] = useState<number>(0);
  const [userContractCourseName, setUserContractCourseName] = useState<
    "Webデザイン" | "Webアプリケーション" | undefined
  >();
  const [contractStudetData, setContractStudentData] = useState<
    ContractStudentType | undefined
  >();
  const [studentClassroomData, setStudentClassroomData] = useState<any>();

  const isBasicInfoInputCompleted = (registerData: UserType): number => {
    const keys = Object.keys(registerData);
    for (const key of keys) {
      if (registerData[key] === "" || registerData[key] === null) {
        return 1;
      }
    }
    return 2;
  };

  const signin = async (signinUser: UserType, callback?: () => void) => {
    setUser(signinUser);
    const isBasicInfoCompleted = await isBasicInfoInputCompleted(signinUser);
    setIsUserRegistered(isBasicInfoCompleted);
    if (callback) {
      callback();
    }
  };

  const signout = (callback: () => void) => {
    setUser(null);
    callback();
  };

  const value: AuthUserContextType = {
    user,
    isUserRegistered,
    signin,
    signout,
    userContractCourseName,
    setUserContractCourseName,
    contractStudetData,
    setContractStudentData,
    studentClassroomData,
    setStudentClassroomData,
  };

  return (
    <AuthUserContext.Provider value={value}>
      {props.children}
    </AuthUserContext.Provider>
  );
};

export { AuthUserContext, AuthUserProvider, AuthUserContextType };
