import React, { ReactNode } from "react";
import { Link } from "react-router-dom";

type MenuItemProps = {
  menuTitle: string;
  icon: string | undefined;
  link: string;
  isMenuOpen: boolean;
  isExternal?: boolean;
};

export function MenuItem(props: MenuItemProps) {
  const externalAttributes = props.isExternal
    ? {
        target: "_blank",
        rel: "noopener noreferrer",
      }
    : {};
  return (
    <li className='w-full h-12'>
      <Link
        to={props.link}
        className='flex items-center h-full w-full gap-0 p-0 mx-auto pl-9'
        {...externalAttributes}>
        <div className='w-6 h-6'>
          <img src={props.icon} />
        </div>
        <p
          className={`ml-4 block transition-all whitespace-nowrap text-sm ${
            props.isMenuOpen
              ? "w-[calc(100%-48px)]"
              : "w-0 overflow-hidden"
          }`}>
          {props.menuTitle}
        </p>
      </Link>
    </li>
  );
}
