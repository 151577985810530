import React, {useEffect, useState, ReactNode, useContext} from 'react';
import {useAuth0} from '@auth0/auth0-react';
import {useNavigate, useOutletContext} from 'react-router-dom';
import {callCoreApi} from 'services/core-api.service';
import {AxiosRequestConfig} from 'axios';
import {useForm} from 'react-hook-form';
import {PageLoader} from 'components/page-loader';
import {Alert} from 'components/Alert';
import {AuthUserContext, AuthUserContextType} from 'providers/AuthUserProvider';

interface AlertType {
	message: ReactNode;
	status: 'error' | 'success';
}

function FirstAccess() {
	const {user, getAccessTokenSilently, logout} = useAuth0();
	const [isAllModalState, setIsAllModalState] = useState<boolean>(false);
	const [modalNumber, setModalNumber] = useState<number>(0);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [zeroplus_id, setZeroplus_id] = useState<string>('');
	const navigate = useNavigate();
	const [alert, setAlert] = useState<AlertType>({
		message: '',
		status: 'error'
	});
	const mail_address = user!.email;
	const authUserContext = useContext<AuthUserContextType>(AuthUserContext);
	const [contractMailAddress, setContractMailAddress] = useState<string>('');

	useEffect(() => {
		if (user?.['https://zero-plus.io/user_metadata'].zeroplus_id === undefined) {
			setIsAllModalState(true);
		} else {
			navigate('/');
		}
	}, [user]);

	const handleModalChange = (action: 'next' | 'return') => {
		let nowModalNumber = modalNumber;
		if (action === 'next' && modalNumber < 4) {
			nowModalNumber += 1;
			setModalNumber(nowModalNumber);
		} else if (action === 'return' && nowModalNumber > 0) {
			nowModalNumber -= 1;
			setModalNumber(nowModalNumber);
		}
		if (nowModalNumber === 0 || nowModalNumber === 5) {
			setIsAllModalState(false);
		}
	};

	const deleteAuth0User = async () => {
		if (mail_address) {
			const token = await getAccessTokenSilently();
			const config: AxiosRequestConfig = {
				method: 'delete',
				url: `${process.env.REACT_APP_API_URL}/customer/user/auth0/delete/`,
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`
				},
				data: {
					mail_address: mail_address
				}
			};
			const {error} = await callCoreApi(config);
			if (error) {
				await setAlert({
					message: 'エラーが発生しました。もう一度やり直してください。',
					status: 'error'
				});

				return true;
			}
			setTimeout(() => setAlert({message: '', status: 'error'}), 5000);
			return true;
		}
	};

	const postHandOverCode = async (data: any) => {
		setIsLoading(true);
		const token = await getAccessTokenSilently();
		const config: AxiosRequestConfig = {
			method: 'patch',
			url: `${process.env.REACT_APP_API_URL}/customer/handover_code/use/`,
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`
			},
			data: {
				handover_code: data.handover_code
			}
		};

		const {responseData, error, status, errorData} = await callCoreApi(config);
		if (responseData) {
			window.location.href = '/';
		}

		if (error) {
			console.log(error);
			if (status === 404) {
				await setZeroplus_id(errorData.zeroplus_id);
				await setContractMailAddress(errorData.email);
				await setModalNumber(404);
			} else if (status === 500) {
				await setAlert({
					message: 'エラーが発生しました。引き継ぎコードが正しいか確認してください。',
					status: 'error'
				});
			} else if (status === 400) {
				await setAlert({
					message: 'エラーが発生しました。もう一度やり直してください。',
					status: 'error'
				});
			}
			setIsLoading(false);
			setTimeout(() => setAlert({message: '', status: 'error'}), 5000);
		}
	};

	const postEmailAndGetHandOverCode = async (email: string) => {
		setIsLoading(true);
		const token = await getAccessTokenSilently();
		const config: AxiosRequestConfig = {
			method: 'post',
			url: `${process.env.REACT_APP_API_URL}/customer/handover_code/create/`,
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`
			},
			data: {
				email: email
			}
		};
		const {error, status} = await callCoreApi(config);
		if (error) {
			if (status === 500) {
				await setAlert({
					message: '入力されたメールアドレスは存在しません。',
					status: 'error'
				});
			}
		}
		if (!error) {
			await setModalNumber(3);
		}
		setIsLoading(false);
		setTimeout(() => setAlert({message: '', status: 'error'}), 5000);
	};

	const patchEmail = async () => {
		const token = await getAccessTokenSilently();
		const email = user!.email;
		const config: AxiosRequestConfig = {
			method: 'patch',
			url: `${process.env.REACT_APP_API_URL}/customer/user/detail/${zeroplus_id}/`,
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`
			},
			data: {
				mail_address: email
			}
		};
		const {responseData, error} = await callCoreApi(config);
		if (error) {
			await setAlert({
				message: 'エラーが発生しました。もう一度やり直してください。',
				status: 'error'
			});
		}
		if (responseData) {
			postHandOverCode(getValues());
		}
		setTimeout(() => setAlert({message: '', status: 'error'}), 5000);
	};

	const {
		register,
		formState: {errors},
		getValues,
		trigger
	} = useForm();

	const handleOnClickFunc = async (action: 'next' | 'return') => {
		if (action === 'next') {
			if (modalNumber === 0) {
				handleModalChange(action);
			} else if (modalNumber === 1) {
				handleModalChange(action);
			} else if (modalNumber === 2) {
				(await trigger('email')) && postEmailAndGetHandOverCode(getValues('email'));
			} else if (modalNumber === 3) {
				(await trigger('handover_code')) && handleModalChange(action);
			} else if (modalNumber === 4) {
				getValues('handover_code').slice(0, 2) === 'ZP' ? postHandOverCode(getValues()) : postHandOverCode(getValues());
			} else if (modalNumber === 404) {
				patchEmail();
			}
		} else if (action === 'return') {
			if (modalNumber === 0) {
				const isDelete = await deleteAuth0User();
				setIsAllModalState(false);
				// auth0からログアウト
				if (isDelete) {
					logout({
						logoutParams: {
							returnTo: `${window.location.origin}/login`
						}
					});
				}
			} else if (modalNumber === 1) {
				navigate('/register');
			} else if (modalNumber === 404) {
				setModalNumber(4);
			} else {
				handleModalChange(action);
			}
		}
	};

	return (
		<div className={`modal w-screen ${isAllModalState && 'modal-open bg-black/[0.8]'}`}>
			{alert.message !== '' && <Alert message={alert.message} status={alert.status} />}
			{isLoading && <PageLoader />}
			<form className='flex flex-col gap-10 justify-center modal-box px-[140px] py-14 min-w-[720px] sm:min-w-0 sm:px-6 min-h-[368px] sm:h-screen'>
				{modalNumber === 0 && (
					<>
						<h3 className='font-bold text-lg text-center'>ご利用アカウントの確認</h3>
						<div className='rounded-full w-10 mx-auto'>
							<img className=' rounded-full' src={user?.picture} />
						</div>
						<p className='text-center font-bold'>{user?.name}</p>
						<p>
							ZeroPlusのサービスをご利用するにあたって、上記のアカウントと紐付けを行います。アカウントにお間違いがなければ、「次に進む」をクリックしてください。
						</p>
					</>
				)}
				{modalNumber === 1 && (
					<>
						<h3 className='font-bold text-2xl text-center'>はじめに</h3>
						<p className='text-center'>ZeroPlusにすでに入会されていますか？</p>
					</>
				)}
				{modalNumber === 2 && (
					<>
						<h3 className='font-bold text-2xl text-center'>メールアドレスを入力 (1/3)</h3>
						<div className='modal-action flex justify-center'>
							<input
								{...register('email', {
									required: true
								})}
								type='email'
								className='input input-bordered input-primary w-full max-w-xs'
							/>
						</div>
						{/* バリデーションエラーの表示 */}
						{errors.email && (
							<span className='mt-4 text-xs text-red-500 flex justify-center'>
								{errors.email && 'メールアドレスを入力してください'}
							</span>
						)}
						<p>
							サービスのご契約時に記載したメールアドレスをご入力ください。入力していただいたメールアドレス宛に引き継ぎコードが送られます。
						</p>
						<p>
							ご不明の場合は、
							<a
								href={`${process.env.REACT_APP_CONTACT_FORM_URL}&entry.879531967=${mail_address}`}
								className=' text-[#5AC5ED] underline'
								target='_blank'
								rel='noopener noreferrer'
							>
								こちら
							</a>
							からお問い合わせください。
						</p>
					</>
				)}
				{modalNumber === 3 && (
					<>
						<h3 className='font-bold text-2xl text-center'>引き継ぎコードを入力 (2/3)</h3>
						<div className='modal-action flex justify-center'>
							<input
								{...register('handover_code', {
									required: true
								})}
								// TODO:errorの作成
								type='text'
								className='input input-bordered input-primary w-full max-w-xs'
							/>
						</div>
						{/* バリデーションエラーの表示 */}
						{errors.handover_code && (
							<span className='mt-4 text-xs text-red-500 flex justify-center'>
								{errors.handover_code && '引き継ぎコードを入力してください'}
							</span>
						)}
						<p>運営からサービス利用時に入力したメールアドレス宛に引き継ぎコードが送られています。</p>
						<p>
							ご不明の場合は、
							<a
								href={`${process.env.REACT_APP_CONTACT_FORM_URL}&entry.879531967=${mail_address}`}
								className='text-[#5AC5ED] underline'
								target='_blank'
								rel='noopener noreferrer'
							>
								こちら
							</a>
							からお問い合わせください。
						</p>
						<p>
							引き継ぎコードのメールが届かない場合は、
							<span
								className=' text-[#5AC5ED] underline'
								onClick={() => postEmailAndGetHandOverCode(getValues('email'))}
							>
								こちら
							</span>
							から再送信ができます。
						</p>
					</>
				)}
				{modalNumber === 4 && (
					<>
						<h3 className='font-bold text-2xl text-center'>引き継ぎコードの確認 (3/3)</h3>
						{/* modalNumber===2の時に入力した引き継ぎコードを表示 */}
						<p className='text-center'>{getValues('handover_code')}</p>
						<p className='text-center'>上記のコードで間違いないですか？</p>
					</>
				)}
				{modalNumber === 404 && (
					<>
						<h3 className='font-bold text-2xl text-center'>メールアドレス更新の確認</h3>
						{/* modalNumber===2の時に入力した引き継ぎコードを表示 */}
						<p className='text-center'>ご契約時に登録いただいたアドレス</p>
						<p className='text-center'>{contractMailAddress}</p>
						<p className='text-center'>今回ログインしているアドレス</p>
						<p className='text-center'>{mail_address}</p>
						<p>
							ご契約時に登録したメールアドレスと異なるようです。
							<span className='font-bold'>
								登録情報を今回ログインしていただいた上記のメールアドレスに更新いたします。
							</span>
						</p>
					</>
				)}
				<div className='flex justify-center sm:flex-col gap-4 items-center'>
					<button
						type='button'
						onClick={() => handleOnClickFunc('return')}
						className={`btn min-w-[140px] font-bold w-[200px] ${
							modalNumber === 1 && ' hover:opacity-50 bg-[#43CFE8] border-none text-white'
						}`}
					>
						{modalNumber === 0 && 'キャンセル'}
						{modalNumber === 1 && '新規入会'}
						{modalNumber === 2 && '戻る'}
						{modalNumber === 3 && '戻る'}
						{modalNumber === 4 && 'いいえ'}
						{modalNumber === 404 && '戻る'}
					</button>
					<button
						type='button'
						onClick={async () => {
							handleOnClickFunc('next');
						}}
						className='btn btn-primary min-w-[140px] w-[200px] font-bold'
					>
						{modalNumber === 0 && '次に進む'}
						{modalNumber === 1 && 'はい'}
						{modalNumber === 2 && '送信'}
						{modalNumber === 3 && '確認'}
						{modalNumber === 4 && 'コードを送信'}
						{modalNumber === 404 && '更新する'}
					</button>
				</div>
			</form>
		</div>
	);
}

export default FirstAccess;
