import React, {useEffect, useState, useContext} from 'react';
import PageTitle from 'components/PageTitle';
import Button from 'components/Button';
import {Link} from 'react-router-dom';
import {useOutletContext} from 'react-router-dom';
import {AxiosRequestConfig} from 'axios';
import {callCoreApi} from 'services/core-api.service';
import {useAuth0} from '@auth0/auth0-react';
import {AuthUserContext, AuthUserContextType} from 'providers/AuthUserProvider';

type LessonList = {
	lesson_datetime: string;
	lesson_material_url: string;
	lesson_name: string;
	lesson_tag: string;
}[];

type LessonData = {
	lesson_date_month: string;
	lesson_date_day: string;
	lesson_time_start: string;
	lesson_time_end: string;
	lesson_datetime: string;
	lesson_material_url: string;
	lesson_name: string;
	lesson_tag: string;
}[];

const WebDesignLessonList: Record<string, string> = {
	Lesson01: 'Webサイトマークアップ入門',
	Lesson02: '下層ページの作成と新しいCSS技法の実践1',
	Lesson03: 'レスポンシブWebデザイン入門',
	Lesson04: 'CSS設計とheadタグの基礎',
	Lesson05: 'Webサイトマークアップ応用',
	Lesson06: '下層ページの作成と新しいCSS技法の実践2',
	Lesson07: '表と問い合わせフォームのマークアップ',
	Lesson08: 'レスポンシブWebデザイン応用',
	Lesson09: 'JavaScriptの基礎と活用',
	Lesson10: 'ライブラリGSAPを使ったアニメーション',
	オンデマンド01: 'ライブラリGSAPの応用',
	オンデマンド02: 'ライブラリSwiperを使ったUIアニメーション',
	オンデマンド03: 'WordPressの概要・テンプレート化',
	オンデマンド04: 'メインループとサブループ',
	オンデマンド05: '固定ページ・カスタム系機能',
	オンデマンド06: '問い合わせフォーム・サーバーアップ',
	Lesson11: 'ワイヤーフレーム制作・デザインの原理原則',
	Lesson12: 'デザインカンプ制作',
	Lesson13: 'コンタクトフォームのデザイン制作',
	オンデマンド07: 'Figmaの機能紹介',
	Lesson14: '要件定義・スケジュール設計',
	Lesson15: 'デザイン・コーディングのブラッシュアップ',
	オンデマンド08: 'FTPソフトを用いたサーバーアップ',
	Lesson16: '総仕上げ、卒業制作物の発表'
};

const WebAppLessonList: Record<string, string> = {
	Lesson01: 'Webサイトマークアップ入門',
	Lesson02: '下層ページの作成と新しいCSS技法の実践',
	Lesson03: 'レスポンシブデザインとheadタグの基礎',
	Lesson04: '高品質UIのCSSフレームワーク活用',
	Lesson05: 'JavaScript初級：基礎構文とDOM入門',
	Lesson06: 'JavaScript中級：構文とDOM操作',
	Lesson07: '配列操作入門：データ追加とビュー表示',
	Lesson08: '配列操作中級：データ編集と削除',
	Lesson09: 'React.jsによるフロントエンド開発入門',
	Lesson10: 'React.jsでのAPI接続実装',
	Lesson11: 'TypeScript基礎：型意識のコーディング',
	Lesson12: 'TypeScriptでのモダンReact開発',
	Lesson13: 'MySQLによるデータベース構築基礎',
	Lesson14: 'SQL基本：四大命令文でのデータ操作',
	Lesson15: 'SQL応用：条件によるデータ抽出',
	Lesson16: 'Node.jsでのサーバーサイド開発入門',
	Lesson17: 'Webフレームワークによるデータ受け渡し',
	Lesson18: 'Express.jsとReact.jsの連携技術',
	Lesson19: 'AWSを使用したWebアプリケーション公開',
	Lesson20: 'AWSでのバックエンド環境構築技術',
	Lesson21: '卒業制作：機能要件完成・開発スケジュールの完成',
	Lesson22: '卒業制作：デザイン完成・開発環境構築',
	Lesson23: '卒業制作：フロントエンド完了',
	Lesson24: '卒業制作：バックエンド完了・ローンチ・発表'
};

function Lesson() {
	const {cookies, setIsLessonTutorialModalOpen} = useOutletContext() as {
		cookies: {isLessonTutorial: boolean};
		setIsLessonTutorialModalOpen: (isOpen: boolean) => void;
	};
	const [lessonMtgUrl, setLessonMtgUrl] = useState<string>('');
	const [lessonList, setLessonList] = useState<LessonData>([]);
	const {user, getAccessTokenSilently} = useAuth0();
	const gradientBorderStyle = {
		border: '8px solid',
		borderRadius: '8px',
		borderImage: 'linear-gradient(90deg, #FFB723 0%, #FF9552 100%) 1'
	};

	const gradientBorderStyle2 = {
		border: '8px solid',
		borderRadius: '8px',
		borderColor: '#43CFE8'
	};
	const gradientLessonBorderStyle = {
		borderLeft: '8px solid',
		borderImageSlice: 1,
		borderImageSource: 'linear-gradient(to right, #FFB723 20%, #FF9552 100%)'
	};
	const gradientButtonStyle = {
		borderBottom: '4px solid',
		borderImage: 'linear-gradient(90deg, #FFB723 0%, #FF9552 100%) 1'
	};

	const authUserContext = useContext<AuthUserContextType>(AuthUserContext);
	const contractName = authUserContext.userContractCourseName;
	const zeroplusId = authUserContext.user?.zeroplus_id;

	const sanitaizeZeroplusId = zeroplusId?.replace(/-/g, '');

	const getLessonMtgUrl = async () => {
		const token = await getAccessTokenSilently();
		const zeroplus_id = user?.['https://zero-plus.io/user_metadata'].zeroplus_id;
		const config: AxiosRequestConfig = {
			method: 'get',
			url: `${process.env.REACT_APP_API_URL}/customer/classroom/zp_id/${zeroplus_id}/`,
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`
			}
		};
		const {responseData, error} = await callCoreApi(config);
		if (responseData) {
			setLessonMtgUrl(responseData[0].classroom_description);
		}
	};

	const changeLessonListDatetimeStyle = (lessonList: LessonList) => {
		return lessonList.map((lessonData) => {
			const lesson_datetime = lessonData.lesson_datetime;
			const lesson_datetime_split = lesson_datetime.split('T');
			const lesson_date = lesson_datetime_split[0];
			const lesson_time = lesson_datetime_split[1].split('+')[0];
			const lesson_date_split = lesson_date.split('-');
			const lesson_date_month = `${lesson_date_split[1]}月${lesson_date_split[2]}日`;
			const lesson_date_day = `（${new Date(lesson_date).toLocaleDateString('ja-JP', {weekday: 'short'})}）`;
			const lesson_time_start = new Date(`${lesson_date}T${lesson_time}`).toLocaleTimeString('ja-JP', {
				hour: '2-digit',
				minute: '2-digit'
			});
			const lesson_time_end = new Date(
				new Date(`${lesson_date}T${lesson_time}`).getTime() + 2 * 60 * 60 * 1000
			).toLocaleTimeString('ja-JP', {hour: '2-digit', minute: '2-digit'});
			return {
				...lessonData,
				lesson_date_month,
				lesson_date_day,
				lesson_time_start,
				lesson_time_end
			};
		});
	};

	const getLessonList = async () => {
		const token = await getAccessTokenSilently();
		const zeroplus_id = user?.['https://zero-plus.io/user_metadata'].zeroplus_id;
		const config: AxiosRequestConfig = {
			method: 'get',
			url: `${process.env.REACT_APP_API_URL}/customer/lesson/list/${zeroplus_id}/`,
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`
			}
		};
		const {responseData, error} = await callCoreApi(config);
		if (responseData) {
			const chengeDatetimeResponseData = changeLessonListDatetimeStyle(responseData);
			setLessonList(chengeDatetimeResponseData);
		}
		if (error) {
			return null;
		}
	};

	useEffect(() => {
		getLessonMtgUrl();
		getLessonList();
		if (cookies.isLessonTutorial === undefined) {
			setIsLessonTutorialModalOpen(true);
		}
	}, []);

	return (
		<div className='mx-4'>
			<PageTitle
				title='通常講義'
				overview='通常講義に関しての、参加URL(Google Meet)、
授業スケジュール、授業教材などはこちらのページをご覧ください。'
			/>
			<div className='grid grid-cols-2 sm:grid-cols-1 grid-rows-1 gap-8 mb-20'>
				<div style={gradientBorderStyle} className='px-8 py-10 bg-white rounded-lg'>
					<h3 className='flex items-center text-xl font-bold mb-10'>
						<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' className='w-6 h-6 mr-2'>
							<circle cx='12' cy='12' r='12' fill='url(#paint0_linear_1778_4054)' />
							<mask id='path-2-inside-1_1778_4054' fill='white'>
								<path d='M13.6606 4L18.8029 10.1284L10.3765 17.199L5.23416 11.0707L13.6606 4Z' />
							</mask>
							<path
								d='M10.3765 17.199L8.84437 18.4846L10.1299 20.0167L11.662 18.7311L10.3765 17.199ZM17.5174 8.59627L9.09088 15.6669L11.662 18.7311L20.0885 11.6604L17.5174 8.59627ZM11.9085 15.9134L6.76624 9.78509L3.70207 12.3562L8.84437 18.4846L11.9085 15.9134Z'
								fill='white'
								mask='url(#path-2-inside-1_1778_4054)'
							/>
							<defs>
								<linearGradient
									id='paint0_linear_1778_4054'
									x1='0'
									y1='12'
									x2='24'
									y2='12'
									gradientUnits='userSpaceOnUse'
								>
									<stop stop-color='#FFB723' />
									<stop offset='1' stop-color='#FF9552' />
								</linearGradient>
							</defs>
						</svg>
						<span>通常講義の参加はこちら</span>
					</h3>
					<p className='leading-8 mb-10'>
						通常講義に出席する場合は、こちらのGoogle Meetから。（講義開始の10分前から参加が可能）
						<br />
						では、いってらっしゃい！
					</p>
					<div className='text-center'>
						{/* TODO: リンクを修正する */}
						<Button title='Google Meetへ' to={lessonMtgUrl} isExternal />
					</div>
				</div>

				<div style={gradientBorderStyle2} className='px-8 py-10 bg-white rounded-lg'>
					<h3 className='flex items-center text-xl font-bold mb-10'>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='24'
							height='24'
							viewBox='0 0 24 24'
							fill='none'
							className='w-6 h-6 mr-2'
						>
							<circle cx='12' cy='12' r='12' fill='#5AC5ED' />
							<mask id='path-2-inside-1_1863_4245' fill='white'>
								<path d='M13.6604 4L18.8027 10.1284L10.3762 17.199L5.23391 11.0707L13.6604 4Z' />
							</mask>
							<path
								d='M10.3762 17.199L8.84412 18.4846L10.1297 20.0167L11.6618 18.7311L10.3762 17.199ZM17.5171 8.59627L9.09064 15.6669L11.6618 18.7311L20.0883 11.6604L17.5171 8.59627ZM11.9083 15.9134L6.766 9.78509L3.70182 12.3562L8.84412 18.4846L11.9083 15.9134Z'
								fill='white'
								mask='url(#path-2-inside-1_1863_4245)'
							/>
						</svg>
						<span>通常講義を欠席する場合</span>
					</h3>
					<p className='leading-8 mb-10'>
						こちらのGoogle Formより欠席の旨を入力して連絡しましょう。
						<br />
						次回だけではなく、先の講義の欠席連絡も可能です。
					</p>
					<div className='text-center'>
						{/* TODO: リンクを修正する */}
						<Link
							className='btn btn-lg w-full max-w-80 gap-2 relative bg-[#43CFE8] border-none text-white'
							to={`https://docs.google.com/forms/d/e/1FAIpQLSeKuSkPiWlsU9u_mx4QAm6RKKsYluk4PfYcQqitq_a9fWrYCg/viewform?usp=pp_url&entry.449650073=${sanitaizeZeroplusId}`}
							target='_blank'
							rel='noopener noreferrer'
						>
							欠席フォームへ
							<svg
								xmlns='http://www.w3.org/2000/svg'
								fill='none'
								viewBox='0 0 24 24'
								strokeWidth={3}
								stroke='currentColor'
								className='w-4 h-4 absolute right-4 top-1/2 -translate-y-1/2'
							>
								<path strokeLinecap='round' strokeLinejoin='round' d='M8.25 4.5l7.5 7.5-7.5 7.5' />
							</svg>
						</Link>
					</div>
				</div>
			</div>
			<div className='bg-white py-10 px-[88px] lg:px-10 md:hidden'>
				<p className='text-center text-[22px] font-bold mb-10'>通常講義スケジュール</p>

				<div className='flex text-[18px] font-bold text-center justify-between'>
					<p style={gradientButtonStyle} className='w-[144px]'>
						実施日
					</p>
					<p style={gradientButtonStyle} className='w-[144px]'>
						Lesson
					</p>
					<p style={gradientButtonStyle} className='w-[320px]'>
						講義内容
					</p>
					<p style={gradientButtonStyle} className='w-[144px]'>
						教材
					</p>
				</div>

				{lessonList.map((data) => (
					<div className='flex h-[92px] border-b-2 py-2 text-4 font-bold justify-between items-center'>
						<p className='w-[144px] flex justify-center'>
							{data.lesson_date_month}
							{data.lesson_date_day} <br />
							{data.lesson_time_start}~{data.lesson_time_end}
						</p>
						<p className='w-[144px] text-center'>{data.lesson_name}</p>
						<p className='w-[320px] text-center'>
							{contractName === 'Webデザイン' && WebDesignLessonList[data.lesson_name]}
							{contractName === 'Webアプリケーション' && WebAppLessonList[data.lesson_name]}
						</p>
						{data.lesson_material_url ? (
							<Link
								to={data.lesson_material_url}
								className='w-[144px] btn btn-primary text-white'
								target='_blank'
								rel='noopener noreferrer'
							>
								教材はこちら
							</Link>
						) : (
							<div className='w-[144px]'></div>
						)}
					</div>
				))}
			</div>
			<div className='hidden md:flex flex-col gap-6'>
				{lessonList.map((data) => (
					<LessonCard
						lessonName={data.lesson_name}
						lessonContent={
							contractName === 'Webデザイン'
								? WebDesignLessonList[data.lesson_name]
								: contractName === 'Webアプリケーション'
								? WebAppLessonList[data.lesson_name]
								: ''
						}
						lessonDateTime={`${data.lesson_date_month}${data.lesson_date_day} ${data.lesson_time_start}~${data.lesson_time_end}`}
					/>
				))}
			</div>
		</div>
	);
}

type LessonCardProps = {
	lessonName: string;
	lessonContent: string | false;
	lessonDateTime: string;
};

const LessonCard = (props: LessonCardProps) => {
	const gradientLessonBorderStyle = {
		borderLeft: '8px solid',
		borderImageSlice: 1,
		borderImageSource: 'linear-gradient(to right, #FFB723 20%, #FF9552 100%)'
	};
	return (
		<div className='bg-white px-4 py-6 rounded-md shadow-md'>
			<p style={gradientLessonBorderStyle} className='mb-4 font-bold text-lg pl-4'>
				{props.lessonName}
			</p>
			<div className='flex justify-between border-b-2 mb-2 pb-2 font-bold'>
				<div className='flex gap-2'>
					<svg
						xmlns='http://www.w3.org/2000/svg'
						fill='none'
						viewBox='0 0 24 24'
						strokeWidth={1.5}
						stroke='currentColor'
						className='w-6 h-6'
					>
						<path
							strokeLinecap='round'
							strokeLinejoin='round'
							d='M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z'
						/>
					</svg>

					<p className='text-sm flex items-center'>授業内容</p>
				</div>
				<p className='text-xs flex items-center'>{props.lessonContent}</p>
			</div>
			<div className='flex justify-between border-b-2 pb-2 font-bold'>
				<div className='flex gap-2'>
					<svg
						xmlns='http://www.w3.org/2000/svg'
						fill='none'
						viewBox='0 0 24 24'
						strokeWidth={1.5}
						stroke='currentColor'
						className='w-6 h-6'
					>
						<path
							strokeLinecap='round'
							strokeLinejoin='round'
							d='M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z'
						/>
					</svg>
					<p className='text-sm flex items-center'>開催日時</p>
				</div>
				<p className='text-xs flex items-center'>{props.lessonDateTime}</p>
			</div>
		</div>
	);
};

export default Lesson;
