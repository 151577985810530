import React, {useContext} from 'react';
import PageTitle from 'components/PageTitle';
import CaseSupportImage1 from 'assets/images/case-support/case-support_img1.png';
import CaseSupportImage2 from 'assets/images/case-support/case-support_img2.png';
import CaseSupportImage3 from 'assets/images/case-support/case-support_img3.png';
import arrow_gradation_bg from 'assets/images/arrow_gradation.png';
import test_image from 'assets/images/project-support_test_img.png';
import iconCheckBlue from 'assets/images/icon_check_blue.svg';
import Button from 'components/Button';
import {AuthUserContext, AuthUserContextType} from 'providers/AuthUserProvider';

function ProjectSupport() {
	const titleGradationBorderStyle = {
		borderLeft: '8px solid',
		borderImage: 'linear-gradient(90deg, #FFB723 0%, #FF9552 100%) 1'
	};
	const authUserContext = useContext<AuthUserContextType>(AuthUserContext);
	const zeroplusId = authUserContext.user?.zeroplus_id;
	const sanitaizeZeroplusId = zeroplusId?.replace(/-/g, '');
	return (
		<div className='lg:mx-4'>
			<PageTitle
				title='案件1on1サポート'
				overview='案件獲得から納品までの徹底サポート！専属メンターや講師が案件に関してのサポートをいたします。
				サポートを受けるためにはテストの合格が必須です🔥合格目指して頑張りましょう！'
			/>
			<div>
				<p className=' text-[22px] font-bold text-center'>案件1on1サポートとは</p>
				<div className='mt-10 flex lg:flex-col lg:items-center lg:gap-5 justify-between'>
					<div className='bg-white w-[330px] max-h-[410px]] px-[32px] py-[34px] '>
						<div className=' flex justify-center'>
							<img src={CaseSupportImage1} alt='' />
						</div>
						<p className='mt-2 text-lg font-bold text-center'>専属メンターサポート</p>
						<p className='mt-2 text-center'>
							あなた自身の目標達成に向けてスムーズに走っていけるように、専属メンターがサポートいたします！
						</p>
					</div>
					<div className='bg-white w-[330px] max-h-[410px]] px-[32px] py-[34px] '>
						<div className=' flex justify-center'>
							<img src={CaseSupportImage2} alt='' />
						</div>
						<p className='mt-2 text-lg font-bold text-center'>ノウハウや資料の提供</p>
						<p className='mt-2 text-center'>
							案件獲得・納品のためのノウハウや資料を提供いたします。そちらを参考に、活動を進めていきましょう！
						</p>
					</div>
					<div className='bg-white w-[330px] max-h-[410px]] px-[32px] py-[34px] '>
						<div className=' flex justify-center'>
							<img src={CaseSupportImage3} alt='' />
						</div>
						<p className='mt-2 text-lg font-bold text-center'>案件に関する質問サポート</p>
						<p className='mt-2 text-center'>
							案件に関して気になることがあれば、いつでもお聞きください！案件に対して知見のある講師が丁寧にサポートいたします！
						</p>
					</div>
				</div>
				<p className='text-center mx-10 mt-10'>
					※ 基本的にクラウドソーシングでのコーディング案件獲得を目指すことを想定しています。
					<br />
					その他の案件についてもご相談のうえサポート可能ですので、都度ご相談ください。
				</p>
				<div className='bg-white mt-20 py-10 px-10'>
					<p className=' text-[22px] font-bold text-center'>サポートまでの流れ</p>
					<div className='flex w-full items-center mt-10 justify-center'>
						<img src={arrow_gradation_bg} alt='' />
					</div>
					<p className='text-center mt-10 text-[#888]'>
						※不合格の場合は、不合格通知とフィードバックの連絡がきます。
						<br />
						ぜひフィードバックをもとに学習をし直してテストにリベンジしましょう！
					</p>
				</div>
				<div className='bg-white mt-20 py-10 px-8'>
					<p className=' text-[22px] font-bold text-center'>テストの要件</p>
					<div className='flex lg:flex-col gap-[90px] lg:gap-5 mt-10 justify-center'>
						<div className=''>
							<p style={titleGradationBorderStyle} className='text-lg font-bold pl-6'>
								テストの実施内容
							</p>
							<p className='mt-4'>
								コーディングのテストになります。配布されるデザインデータを元にコーディングを進めましょう！
							</p>
							<p className='mt-4 font-bold'>【必要となる技術】</p>
							<ul>
								<li>・HTML・CSSによるマークアップができること</li>
								<li>・JavaScriptによるアニメーションができること</li>
								<li>・WordPressオリジナルテーマが構築できること</li>
								<li>・サーバーアップ、サイト表示速度の改善ができること</li>
							</ul>
						</div>
						<div className='w-[1px] bg-slate-400'></div>
						<div>
							<p style={titleGradationBorderStyle} className='text-lg font-bold pl-6'>
								提出期限の目安
							</p>
							<p className='mt-4'>
								<span className='text-[#FF0000] text-2xl font-bold underline'>応募から約20日後</span>
								<br />
								テストの配布と同時に、提出期限が開示されます。そちらをご確認の上、間に合うように取り組みましょう！
							</p>
							<p className='mt-4 text-sm'>※ 期限内に完成しなかった場合でも、提出していただきます。</p>
						</div>
					</div>
					<div className='w-full h-[1px] bg-slate-400 mt-10' />
					<div className='mt-10 flex md:flex-col gap-8'>
						<div className='w-[400px] md:w-full shrink-0'>
							<img src={test_image} alt='' />
						</div>
						<div className='flex flex-col justify-center'>
							<p style={titleGradationBorderStyle} className='text-lg font-bold pl-6'>
								テストの内容・規模
							</p>
							<p className='mt-4'>
								応募後、左の画像のようなデザインデータをお渡しさせていただきます。そちらのデザインをサイトとして再現するコーディングテストになります。
								<br />
								コーディング用のファイルも同時に送付させていただきますので、そちらを元に進めましょう！
							</p>
						</div>
					</div>
				</div>
				<div className='px-8 py-10 bg-white mt-20'>
					<p className=' text-[22px] font-bold text-center'>応募資格</p>
					<p className='mt-4 text-center'>
						以下の応募資格を確認した上で、条件を満たしている方はご応募ください！
						<br />
						応募条件を満たしていない方で、ぜひサポートを受けたい方は、Slackにて別途ご相談ください。
					</p>
					<div className=' grid grid-cols-2 lg:grid-cols-1 gap-10 mx-[60px] md:mx-4 mt-10'>
						<div className='bg-[#FAFAFA] rounded-md py-6 px-8 text-center'>
							<div className='flex justify-center'>
								<img src={iconCheckBlue} alt='' />
							</div>
							<p className='font-bold mt-4'>
								ZeroPlus Webデザインコースの補講動画一覧にある講義をすべて受講完了していること。
							</p>
							<p className='mt-4'>※授業に参加した講義の補講動画は未視聴でも構いません。</p>
						</div>
						<div className='bg-[#FAFAFA] rounded-md py-6 px-8 text-center'>
							<div className='flex justify-center'>
								<img src={iconCheckBlue} alt='' />
							</div>
							<p className='font-bold mt-4'>テストを必ず提出できる人</p>
							<p className='mt-4'>
								提出したテストがいかなる完成状態でも、 フィードバックをお送りさせていただきます。
								未完成でも必ずご提出ください。
							</p>
						</div>
						<div className='bg-[#FAFAFA] rounded-md py-6 px-8 text-center'>
							<div className='flex justify-center'>
								<img src={iconCheckBlue} alt='' />
							</div>
							<p className='font-bold mt-4'>稼働時間が20時間以上/週あること</p>
							<p className='mt-4'>案件獲得や納品の活動をするために 必要となります。</p>
						</div>
						<div className='bg-[#FAFAFA] rounded-md py-6 px-8 text-center'>
							<div className='flex justify-center'>
								<img src={iconCheckBlue} alt='' />
							</div>
							<p className='font-bold mt-4'>
								本気で案件を獲得して納品するという
								<br />
								覚悟があること
							</p>
							<p className='mt-4'>案件獲得の目的が就職活動・転職活動の方は、別途Slack等でご相談ください。</p>
						</div>
					</div>
					<div className='mx-[60px] md:mx-4 mt-10'>
						<div className='bg-[#FAFAFA] rounded-md py-6 px-8 text-center w-full mt-10 mx-auto max-w-[428px] lg:max-w-none'>
							<div className='flex justify-center'>
								<img src={iconCheckBlue} alt='' />
							</div>
							<p className='font-bold mt-4'>ポートフォリオを保有していること</p>
							<p className='mt-4'>案件獲得のための提案の際に必要となります。ZeroPlusのカリキュラム「実践制作」で作成したものは対象外となります。</p>
						</div>
					</div>
				</div>
				<div className='py-10 bg-white mt-20 text-center'>
					<p className='text-[22px] font-bold'>応募フォーム</p>
					<p className='mt-6 mb-10'>
						応募はこちらのGoogle Formから。
						<br />
						上記のテスト要件・応募資格の内容をよく読んだのち、応募しましょう！
					</p>
					<Button
						title='Google Formへ'
						to={`https://docs.google.com/forms/d/e/1FAIpQLSearVzrn_sOKHMAk1jyiCddP7U8tSDEcElifna8uc0eeHvbmA/viewform?usp=pp_url&entry.981681638=${sanitaizeZeroplusId} `}
						isExternal
					/>
				</div>
			</div>
		</div>
	);
}

export default ProjectSupport;
